@import 'src/styles';

.reset_password_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 345px;
  height: calc(100% - var(--keyboard-inset-height, 0px));
  margin: 0 auto;
  transition: height .3s ease-in-out;
  padding-bottom: 40px;

  .button {
    max-width: $maxWidthDefault;
  }

  .top_part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;

    .title {
      position: relative;
      z-index: 2;
      font-size: $resetPasswordTitleFontSize;
      font-weight: $fwstb;
      margin-top: 80px;
      color: $resetPasswordTitleColor;
    }
  }

  .button {
    &:disabled {
      opacity: $resetPasswordDisabledOpacity;
      background: $createAccountNonActiveBtnBg;

      .button_title {
        color: $createAccountNonActiveBtnText;
      }
    }
  }
}

.blur {
  @include map-styles($resetPasswordBlurStyles);
}

.success_popup {
  background-color: $resetPasswordSuccessPopupBg;

  h2 {
    color: $resetPasswordSuccessPopupTitleColor;
  }

  p {
    color: $resetPasswordTextColor;

    b {
      font-weight: $resetPasswordSuccessPopupEmailFontWeight;
    }
  }
}