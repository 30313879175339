@import "src/styles/index";

.overlay {
  position: fixed;
  z-index: 1001;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);

  .modal {
    background: $errorPopupBgColor;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    max-width: 345px;
    min-height: 240px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    z-index: 10001;
    border: 0;
    outline: 0;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: $fontSize22;
      line-height: $fontSize30;
      text-align: center;
      color: $errorPopupTitleColor;
    }

    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: $fontSize18;
      line-height: $fontSize26;
      color: $errorPopupSubtitleColor;
      margin-top: 15px;
      text-align: center;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $errorPopupPrimaryColor;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
      margin-top: 30px;

      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: $fontSize22;
        line-height: $fontSize30;
        color: $white;
        letter-spacing: -0.57px;
        text-align: center;
      }
    }

    .skip {
      color: $errorPopupPrimaryColor;
      margin-top: 16px;
      text-decoration: none;
    }
  }
}