@import 'src/styles';

.actionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: $fontFamily;

  .sumbitTitle {
    font-weight: $fwstb;
    font-size: $fontSize20;
  }

  .skip {
    margin: 1vh 0 1.5vh;
    color: $primaryText;
    color: $skipBtnColor;
  }
}