@import "src/styles";

.overlay {
  background-color: rgba(192, 192, 192, 0.55);
  z-index: 110;
  height: 100dvh;
  inset: 0;
  position: fixed;
  width: 100%;
  transition: $transition;
  z-index: 100;

  .modal {
    transform: translate3d(-50%, 100%, 0);
    transition: all 0.5s ease-in-out;
    position: fixed;
    left: 50%;
    bottom: 0;
    overflow-y: scroll;
    border-radius: 30px;
    margin: 0;
    outline: none;
    z-index: 110;

    &.modal-content-after-open {
      transform: translate3d(-50%, 0%, 0);
    }

    &.modal-content-before-close {
      transform: translate3d(-50%, 100%, 0);
    }

    &.centered {
      top: 50%;
      bottom: auto;

      &.modal-content-after-open {
        transform: translate3d(-50%, -50%, 0);
      }

      &.modal-content-before-close {
        transform: translate3d(-50%, 100%, 0);
      }
    }
  }
}