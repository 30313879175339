@import 'src/styles';

.overlay {
  position: fixed;
  z-index: 2;
  inset: 0;
  backdrop-filter: blur(3.5px);
  background: $manageOverlayBg;

  .modal {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 25px;
    background: var(--mainBg);
    border-radius: 40px 40px 0 0;
    transform: translateX(-50%);
    outline: none;

    .close {
      position: absolute;
      right: 20px;
      top: 20px;

      svg {
        path {
          fill: $manageReviewResultPopupClose;
        }
      }
    }

    .title {
      font-family: $managePrimaryFontFamily;
      font-weight: $manageReasonPopupTitleFW;
      font-size: $fontSize26;
      line-height: $fontSize32;
      color: $manageReviewResultPopupText;
      margin: 20px 0 10px;
    }

    .subtitle {
      font-family: $manageSecondaryFontFamily;
      line-height: $fontSize23;
      margin-bottom: 45px;
      color: rgba($color: $manageReviewResultPopupText, $alpha: .8);
      font-size: $fontSize16;
      font-weight: $fwn;
    }

    .text_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      padding: 10px 15px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: $manageTextareaBg;
      margin-bottom: 45px;

      .text {
        color: rgba($color: $manageSubtitleColor, $alpha: .5);
        font-family: $manageSecondaryFontFamily;
        font-size: $fontSize12;
        font-weight: $fwn;
        line-height: $fontSize18;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: calc(5.8 * $fontSize18);
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;

      .button__title {
        font-family: $manageReasonPopupButtonFF;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.57px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        font-size: $manageReasonPopupButtonFS;
        line-height: $fontSize26;
        color: $white;
      }
    }
  }
}