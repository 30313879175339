@import 'src/styles';

.account_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 345px;
  height: fit-content;
  min-height: calc(100svh - var(--keyboard-inset-height, 0px));
  margin: -60px auto 0;
  padding-bottom: 40px;
  transition: min-height .3s ease-in-out;

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    margin-top: 15px;
    color: $createAccountTitleColor;

    @include map-styles($createAccountTitleStyles);
  }

  .subtitle {
    font-size: $fontSize16;
    font-weight: $fwn;
    margin: 15px 0 20px;

    @include map-styles($createAccountSubtitleStyles);
  }

  .flowWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}