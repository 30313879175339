@import "src/styles";

.text {
  margin: 0 auto 2.5vh;
  color: $primaryText;
  font-size: $fontSize11;
  font-family: $fontFamily;
  line-height: 1.45;
  text-align: center;
  opacity: 0.4;
}