@import 'src/styles';

.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-height: 55px;
  max-width: 100%;
  width: 100%;
  padding: 15px;
  color: #1f1f1f;
  font-size: $fontSize16;
  font-weight: $fwsb;
  background-color: $white;
  border: 1px solid #747775;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);

  @include map-styles($createAccountSocialButtonStyles);

  &:disabled {
    opacity: .5;
  }
}