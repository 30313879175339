@import 'src/styles';

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;

  .top_part {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.centered_content {
      top: 25%;
    }

    h2 {
      margin: 25px 0 15px;
      font-size: $fontSize26;
    }

    .sub_title {
      color: $resetPasswordSuccessTextColor;
      text-align: center;
      font-size: $fontSize15;
      font-weight: $fwn;
      line-height: $fontSize24;
      padding: 0 30px;
    }
  }

}