@import 'src/styles';

.overlay {
  background-color: rgba($manageBg, 0.8);

  .modal {
    width: 100%;
    background-color: $manageModalBg;
    border-radius: 40px 40px 0 0;
    padding: 55px 20px 40px 20px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .close {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      path {
        fill: $manageAccentColor;
      }
    }
  }

  .option {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $manageSubtitleColor;
    font-size: $fontSize18;
    font-weight: 400;
    line-height: normal;
    font-family: $fontFamily;
  }
}