@import 'core/styles';

// colors
$blackColor: #000000;
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$mainBgColor: #f7f7f7;
$primaryColor: #ee8f61;
$primaryText: #333333;
$secondaryColor: #16786c;
$mineShaft: #2d2d2d;
$scorpion: #565656;
$secondaryGray: #c7c7c7;
$genoa: #16786c;
$apricot: #ee8f61;
$redDamask: #dc7f4b;
$oldLace: #fdeee4;
$cocoa: #785643;
$romanCoffee: #83614c;
$mystic: #d9eae8;
$darkGrayBlue: #335662;
$eggWhite: #fff2c4;
$darkGrayBlue: #335662;
$cloudyBlue: #b9d7d1;
$breakerBay: #69a79b;
$gray: #818181;
$darkTeal: #2E897F;
$grayLight: #e0e0e0;
$graySpecial: #575757;
$graySpecialTwo: #797979;
$grayOpacity: rgba(45, 45, 45, 0.4);
$trans: transparent;
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$beige: #f2f2f2;
$dark-beige: #c7b8ab;
$lightGreen: #e1eeed;
$shadowColor: rgba(183, 181, 170, 0.3);
$borderColor: rgba(184, 185, 195, 0.3);
$darkOrange: #C56554;
$linen: #FCF4F0;

$injectedPaymentPrimaryColor: var(--buttonBackground);

$engVersionPrimary: rgba($secondaryColor, 0.7);

$accentTextLineThroughColor: rgba(197, 101, 84, 1);

$trialsLeftBadgeBg: var(--trialsLeftBadgeBg);

$pageWrapperTopOffset: 80px;

//fonts
$fontKalam: 'Kalam', cursive;

// buttons
$buttonBgColor: $primaryColor;
$disabledButtonBg: $oldLace;
$disabledButtonColor: $dark-beige;

// links
$linkColor: #2879d8;

// form
$colorLabel: #333333;
$colorFocus: #2879d8;
$colorTextField: #333333;
$colorPlaceholder: #dbdbdb;
$colorBorderField: rgba(184, 185, 195, 0.3);
$colorBgSuccesMessage: #f2ffe5;

$inputColor: $secondaryGray;
$inputCaretColor: $genoa;

$checkboxMarkBackground: $primaryColor;
$checkboxMarkCheckedBackground: $primaryColor;

$titleH2Color: $mineShaft;

$storeStatsBlockBorder: 1px solid $oldLace;

// questions
$questionActiveTitleColor: #2E897F;
$questionActiveTitleBorderColor: #2E897F;

$paymentOverlay: $mainBgColor;

// cookie modal

$cookieModalIcon: #E0E0E0;
$cookieModalTextColor: #2D2D2D;
$cookieModalBg: $white;
$cookieModalPrimary: $primaryColor;
$cookieModalFootnoteText: rgba(#2D2D2D, 0.40);

// users joined

$usersJoinedBoldColor: $secondaryColor;
$usersJoinedStarColor: $primaryColor;

// payment
$payModalPrimaryColor: var(--payModalPrimaryColor);
$payModalSecondaryColor: var(--payModalSecondaryColor, $secondaryColor);
$paymentModalLineThroughPriceColor: $payModalSecondaryColor;
$paymentModalLineThroughPriceOpacity: 1;
$payModalDiscountColor: $payModalSecondaryColor;

//email
$mailCheckboxBorderColor: $primaryColor;
$mailCheckboxTextColor: $mineShaft;

//manage 
$manageAlternativeButtonBorder: var(--manageAlternativeButtonBorder);
$manageAlternativeButtonColor: var(--manageAlternativeButtonColor);
$managePrimaryFontFamily: 'Open Sans', sans-serif;
$manageCheckboxBg: #D9EAE8;
$manageAccentColor: #16786C;
$manageCheckboxChecked: #16786C;
$manageCheckmark: white;
$manageBackButtonColor: #2D2D2D;
$manageTextareaBg: #F7F7F7;
$manageTextareaBorder: $primaryColor;
$manageSubtitleColor: #2D2D2D;
$manageOverlayBg: rgba(247, 247, 247, 0.50);
$manageBg: #F7F7F7;
$manageCurrentPlanBg: #D9EAE8;
$plansBadgeColor: var(--plansBadgeColor, #2E897F);
$manageWarningColor: $plansBadgeColor;
$manageContentContainer: (
  'background-color': $white,
  'border-radius': 15px,
  'padding': 3px 10px 15px,
);
$manageTitleColor: $mineShaft;
$manageTitleFontSize: $fontSize22;
$manageBackArrowColor: #565656;
$manageBorderColor: rgba(45, 45, 45, 0.10);
$manageCPSecondaryColor: $mineShaft;
$manageFeatureTitleFontSize: $fontSize16;
$manageFeatureBgColor: $white;
$manageFeatureBorder: none;
$manageFeatureCheckBgColor: #F2F7F6;
$manageFeatureCheckIconColor: #16786C;
$manageCancelLinkColor: $scorpion;
$manageCancelSuccessTitleColor: $mineShaft;
$manageCancelSuccessTitleFontSize: $fontSize22;
$manageCancelSuccessPadding: 15px 20px;
$manageCancelSuccessHighlightTextFontWeight: 700;
$manageEmailCancelEmailColor: #27BAA7;
$manageCanceledStatusColor: $manageCPPrimaryColor;
$manageLinkBtnFontSize: $fontSize14;
$manageFeaturesTitleFontSize: $fontSize22;
$manageCurrentPlanLeftTextSize: $fontSize14;
$manageReasonButtonDisabledColor: rgba(224, 224, 224, 1);
$manageReasonButtonDisabledOpacity: 1;
$manageTitleFontWeight: $fwstb;
$manageCurrentPlanTitleWeight: $fwb;
$manageBenefitsTitleWeight: $fwb;
$manageReasonPopupTitleSize: $fontSize22;
$manageEmailCancelTitleSize: $fontSize26;


// terminate

$terminateBg: #F7F7F7;
$terminateTitleColor: $mineShaft;
$terminateConfirmFeatureTitleSize: $fontSize26;

// create account
$createAccountTitleColor: $primaryText;
$createAccountBorderColor: #B8B9C3;
$createAccountActiveBorderColor: $darkTeal;
$createAccountAsteriskColor: $primaryColor;
$createAccountDisabledBtnColor: '';
$createAccountDisabledBtnOpacity: '';

$progressCurrentTextColor: var(--primaryText);
$progressPrevLineColor: var(--primaryColor);
$progressAccentLineColor: $grayLight;
$progressAfterLineHeight: 2px;
$progressAccentColor: var(--primaryColor);
$progressNotAccentColor: var(--secondaryBg);

//preloader
$preloaderColor: #2d2d2d;

//error popup
$errorPopupPrimaryColor: var(--primaryColor);
$errorPopupTitleColor: var(--primaryColor);