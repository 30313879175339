@import 'src/styles';

.dividerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
  width: 100%;
  max-width: 70%;
  color: $createAccountDividerColor;
  font-size: $fontSize15;
  font-weight: $fwb;
  text-align: center;

  .line {
    width: 100%;
    height: 1px;
    background-color: $createAccountDividerColor;
    opacity: .5;
  }
}