:root {
  --primaryColor: #ee8f61;
  /* Main */
  --primaryColorLight: #ee8f6175;
  /* Tint */
  --secondaryColor: #16786c;
  /* Accent */
  --secondaryColorLight: #16786c75;
  --primaryText: #2d2d2d;
  /* Font */
  --mainBg: #f7f7f7;
  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --secondaryText: white;
  --secondaryBg: white;
  /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --buttonBackground: var(--primaryColor);
  --injectedPaymentPrimaryColor: var(--buttonBackground);
  --titleH2Color: var(--primaryText);
  --progressTextLight: #2d2d2d99;

  --payModalPrimaryColor: var(--primaryColor);
  --trialsLeftBadgeBg: var(--primaryColor);
}

:root[data-theme='monochrome'] {
  --manageAlternativeButtonBorder: 1px solid var(--primaryColor);
  --manageAlternativeButtonColor: var(--primaryColor);
  --primaryColor: #16786C;
  /* Main */
  --primaryColorLight: #16786C75;
  /* Tint */
  --secondaryColor: #16786c;
  /* Accent */
  --secondaryColorLight: #16786c75;
  --primaryText: #2d2d2d;
  /* Font */
  --mainBg: #f7f7f7;
  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --secondaryText: white;
  --secondaryBg: white;
  /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --buttonBackground: var(--primaryColor);
  --injectedPaymentPrimaryColor: var(--buttonBackground);
  --titleH2Color: var(--primaryText);
  --progressTextLight: #2d2d2d99;
  --plansBadgeColor: #EE8F61;
  --trialsLeftBadgeBg: #EE8F61;

  --payModalPrimaryColor: var(--primaryColor);
  --faceReadingProgressNumberColor: var(--primaryColor);
  --faceReadingButtonColor: var(--primaryColor);
}