@import "src/styles";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  font-weight: $fwn;
  line-height: 1.54;
  color: $gray;
  font-size: $fontSize12;
  letter-spacing: 0px;

  a {
    text-decoration: underline;
    color: inherit;
  }

  footer{
    margin-top: -15px;
  }
}