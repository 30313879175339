@import "src/styles";

.wrapper {

  h2 {
    margin-bottom: 10px;
    font-size: $manageFeaturesTitleFontSize;
    font-family: $manageFeatureTitleFF;
  }

  .warning {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    font-size: $manageWarningFontSize;
    color: $manageWarningColor;
    font-weight: 600;
    line-height: $fontSize22;
    font-family: $fontFamily;
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }
}