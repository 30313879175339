@import 'src/styles';

.popup {
  width: 100%;
  background-color: #F7F7F7;
  border-radius: 40px 40px 0 0;
  outline: none;
  border: 0;
  height: fit-content;
  bottom: 0;
  position: absolute;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &.for_prepaid {
    min-height: 50%;
    justify-content: center;
    border-radius: 40px 40px 0 0;

    &.dark {
      .text {
        font-size: $fontSize17;
        line-height: $fontSize25;
        color: rgba(250, 246, 232, 0.80);
      }

      .close {
        path {
          fill: #D0B894;
        }
      }
    }

  }

  .paypal_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 325px;

    .or {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-family: $fontFamily;
      font-size: $fontSize15;
      font-weight: 600;
      width: 100%;
      line-height: $fontSize20;
      text-align: left;
      color: #FAF6E8B2;
      margin-bottom: 12px;


      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        width: calc(50% - 20px);
        height: 1px;
        background-color: #FAF6E833;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0, -50%);
        width: calc(50% - 20px);
        height: 1px;
        background-color: #FAF6E833;
      }
    }
  }

  .paypal {
    max-width: 325px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 100px;
    overflow: hidden;
  }

  .img {
    width: auto;
    max-width: 345px;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .title {
    font-weight: 700;
    font-size: $fontSize26;
    line-height: $fontSize32;
    color: $mineShaft;
    margin: 0 25px;
  }

  .text {
    font-size: $fontSize15;
    line-height: 1.46;
    color: $mineShaft;
    margin: 20px 25px 28px;

    b {
      font-weight: $fwstb;
    }
  }

  .close {
    position: absolute;
    right: 25px;
    top: 25px;

    path {
      fill: #D8D8D8;
    }

  }

  .button {
    margin: 12px 0;
  }

  &.dark {
    background-color: $paymentActionModalBgColor;

    .text {
      color: $paymentActionModalTextColor;
    }

    .title {
      color: $titleH1Color;
    }
  }
}

.option {
  position: relative;
  text-align: left;
  margin: 0 0 20px 30px;
  color: rgba($white, 0.80);
  font-family: $fontFamily;
  font-size: $fontSize16;
  font-style: normal;
  font-weight: 400;
  line-height: $fontSize22;

  .point {
    position: absolute;
    left: -30px;
    top: 2px;
    color: $white;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize13;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 20px;
    margin-right: 10px;
    background-color: #27BAA7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .highlight {
    color: $white;
    font-weight: 600;
  }
}

.cardImage {
  margin: 15px auto 0;
}

.amountLimitCardImage {
  margin: 15px auto 20px;
}

.amountLimitText {
  max-width: 275px;
  font-size: $fontSize14;
  line-height: $fontSize20;
}

.optionsTitle {
  color: $white;
  font-family: $fontFamily;
  font-size: $fontSize16;
  font-weight: 700;
  line-height: $fontSize22;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 20px;
}

.baseText {
  color: rgba($white, 0.80);
  text-align: center;
  font-family: $fontFamily;
  font-size: $fontSize15;
  font-style: normal;
  font-weight: 400;
  line-height: $fontSize22;
  letter-spacing: normal;

  .highlight {
    color: $white;
    font-weight: 600;
  }
}

.email {
  font-size: $fontSize15;
  color: #14D5C2;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.30);
  z-index: 110;

  &.dark {
    background-color: $paymentActionModalOverlayBgColor;
  }
}